<section >
    <div id="prueba">
        <h1 class="centrado4 rounded" ><strong>Reserva para el evento {{evento.nombre}} </strong></h1>
    </div>
</section>

<div class="pagar cetrarTexto container border mt-5 p-3" *ngIf="!cargando&& reserva?.activa==true">
    <p><strong>Nombre de la Reserva:</strong> {{usuario?.nombre}}</p>
    <p><strong>Nombre del promotor:</strong> {{promotor?.nombre}}</p>
    <p><strong>Números de Tickets: </strong>{{getNumerosTicekts()}}</p>
    <p><strong>Cantidad de Tickets: </strong>{{reserva.tickets.length}}</p>
    
    <p><strong>Total de Asientos: </strong>{{calcularTotalAsientos()}}</p>
    <p><strong>Valor Total: </strong>{{calcularTotal()| currency:'COP'}}</p>
     
    <button class="btn action_button" (click)="abrirCarrito()">
          Pagar
    </button>
</div>

<div class="pagar cetrarTexto container border mt-5 p-3" *ngIf="!cargando&&reserva?.activa==false">
    <h4>Esta reserva ya no se encuentra activa</h4>
</div>

<section *ngIf="cargando">
    <div class="container-fluid contendor-spinner-2" >
      <div class="spinner"></div>
    </div>
  </section>
