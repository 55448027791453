import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Palco } from 'src/app/models/palco.model';
import { API_URL_PAGOS } from 'src/app/app.constants';
import { Evento } from 'src/app/eventos/evento.model';
import { Reserva } from 'src/app/models/reserva.model';
import { CommonDataService } from '../commons/common-data.service';

@Injectable({
  providedIn: 'root'
})
export class ReservasDataService extends CommonDataService<Reserva>{


  constructor(protected http: HttpClient) {
    super(http)
   }

protected override baseEndpoint = API_URL_PAGOS +"/reservas"

 protected override atributoListado="reservas";

}