import { EventoDataService } from './../service/data/evento-data.service';

import { Evento } from './evento.model';
import { Component, OnInit } from '@angular/core';

import { HoraPipe } from '../pipes/horas.pipe';

import { DatePipe, registerLocaleData } from '@angular/common';
import localeES from '@angular/common/locales/es';

import { ActivatedRoute, Route, Router } from '@angular/router';

import { Ciudad } from '../models/ciudad.model';
import { MatDialog } from '@angular/material/dialog';
import { MensajeComponent } from '../mensaje/mensaje.component';



@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.css'],
  providers: [

  ]
})
export class EventosComponent implements OnInit {


  eventos: Evento[] = [];
  busqudaNombre: string = ''
  cargando: boolean
  numeroDocumento: string
  idCiudad
  busqueda: string
  ciudades: Ciudad[]
  tipoEvento: string = '';
  imagenesTipo4: any[] = [];
  resultados: any='';
  currentIndex: number= 0;
  intervalId: any;
  transitionActive = true;

  constructor(private eventoDataService: EventoDataService,  private route: ActivatedRoute,private dialog: MatDialog,private router: Router) { }

  ngOnInit(): void {



    this.idCiudad = -1
    this.tipoEvento = "-1"
    this.cargando = true;
    this.route.parent.params.subscribe(params => {

    })

    this.eventoDataService.getAllEventosVisibles().subscribe(
      {
        next: response => {
          this.cargando = false;
          if(response && response.length < 0){
            this.cargando = false;
            this.openMensaje("No hay eventos disponibles")
          }else{
            this.handleSuccesfullGet(response);
          }
        }, error: error => {
          this.cargando = false;
          this.openMensaje("Hay un error, intenta mas tarde")
        }
      }
    )

  }


  buscar() {
    this.cargando=true

    this.eventos = []
    let parametrosBuscado = this.busqudaNombre;
    if (this.busqudaNombre == '') {
      parametrosBuscado = "-1"
    }


    this.eventoDataService.getAllEventosFiltro( this.idCiudad || -1, parametrosBuscado, this.tipoEvento ||"-1")
      .subscribe(response => {
        this.eventos = response.eventos
        this.cargando=false

      },
        error => {
          alert("Sucedio un error")
        })
  }

  darURL(evento: Evento) {
    const primeraImagenTipo1 = evento.imagenes.find(imagen => imagen.tipo === 1);
    var urlPrimeraImagenTipo1 = "";
    if (primeraImagenTipo1) {
      urlPrimeraImagenTipo1 = primeraImagenTipo1.url;
    }
    return urlPrimeraImagenTipo1
  }



  darFecha(evento: Evento): string {

    if(evento.fecha==null){
      return 'Por confirmar'
    }

    registerLocaleData(localeES, 'es');
    let dataPipe: DatePipe = new DatePipe('es');
    let Word = dataPipe.transform(evento.fecha, 'EEE dd');
    Word = Word[0].toUpperCase() + Word.substr(1).toLowerCase();

    let Word2 = dataPipe.transform(evento.fecha, 'MMM');
    Word2 = Word2[0].toUpperCase() + Word2.substr(1).toLowerCase();


    let fecha = Word + " de " + Word2 + " de " + dataPipe.transform(evento.fecha, 'yyyy');

    return fecha
  }

  handleSuccesfullGet(response) {

    this.eventos = response.eventos;
    this.imagenesTipo4 = response.eventos.imagenes
    this.ciudades =response.ciudades;

    this.imagenesTipo4 = [];
    //traerme las imagenes tipo 4
    this.eventos.forEach(evento => {
      if (evento.imagenes && evento.imagenes.length > 0) {
          const imagenesTipo4 = evento.imagenes.filter(imagen => imagen.tipo == 4);
          if(imagenesTipo4.length > 0){
            imagenesTipo4.forEach(imagen => {
              this.imagenesTipo4.push({
                ...imagen,
                eventoId: evento.id
              })
            });
          }
        }
    });

  }

  //hace click en la imagen,lleva al evento de esa imagen
  irAlEvento(eventoId: string){
    this.router.navigate([`/eventos/evento/${eventoId}`])
  }

  openMensaje(mensajeT: string, openD?: string): void {
    let screenWidth = screen.width;
    let anchoDialog: string = '500px';
    let anchomax: string = '80vw';
    let altoDialog: string = '250';
    if (screenWidth <= 600) {
      anchoDialog = '100%';
      anchomax = '100%';
      altoDialog = 'auto';
    }
    const dialogRef = this.dialog.open(MensajeComponent, {
      width: anchoDialog,
      maxWidth: anchomax,
      height: altoDialog,
      data: {
        mensaje: mensajeT,
      },
    });
  }



}


