import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UsuariosDataService } from '../service/data/usuarios-data.service';
import { HardcodedAutheticationService } from '../service/hardcoded-authetication.service';
import { ReservasBoletasService } from '../service/data/reservas-boletas.service'; 

import { Promotor } from 'src/app/models/promotor.model';

import { Localidad } from '../models/localidad.model';
import { Evento } from '../eventos/evento.model';
import { LoginComponent } from '../login/login.component';
import { MensajeComponent } from '../mensaje/mensaje.component';
import { OrdenDataService } from '../service/data/orden-data.service';
import { MatDialog } from '@angular/material/dialog';
import { Reserva } from '../models/reserva.model';
import { ClientePagos } from '../models/cliente-pagos.model';

@Component({
  selector: 'app-reserva-boleta',
  templateUrl: './reserva-boleta.component.html',
  styleUrls: ['./reserva-boleta.component.css'],
  standalone: false
})
export class ReservaBoletaComponent implements OnInit {

  descripcion:string
  miId
  user
  reserva:Reserva
  usuario: ClientePagos
  promotor: Promotor
  pagar:boolean

  valorTotal:number
  localidad:Localidad
  evento:Evento
  tax:number
  url: string
  codigoOrden
  cargando:boolean
  idLocalidad
  constructor(
    private autenticador: HardcodedAutheticationService
    , private route: ActivatedRoute,
    private clienteService: UsuariosDataService,
    private reservasService: ReservasBoletasService ,
    private dialog: MatDialog,
    private ordenService: OrdenDataService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.cargando=true
    this.tax=0
    this.evento = new Evento();
    this.valorTotal=0


    this.pagar = true
    this.usuario = new ClientePagos()
    this.promotor = new Promotor();
    this.localidad = new Localidad();
    this.reserva = new Reserva()
    this.user = this.autenticador.getUsuario();
    if(this.user){
    this.route.paramMap.subscribe(params => {
      this.miId = params.get("id")
      this.reservasService.getReservaPorId(this.miId).subscribe({next:response=>{
        this.reserva = response.reserva
        this.promotor = response.reserva.promotor
        this.evento = response.evento
        this.localidad = response.localidad
        this.valorTotal = this.reserva.cantidad*(this.localidad.precio + this.localidad.servicio + this.localidad.servicio_iva)
        this.clienteService.getCliente(this.user).subscribe((response) => {
          this.usuario = response.usuario
          if(this.reserva.documentoReserva == this.usuario.numeroDocumento){
            this.pagar = false
            this.cargando=false  
            
          }
          else{
            this.openMensaje('Esta reserva no esta a tu nombre');
          }
    
        })
    },error:error=>{
      this.openMensaje("Sucedio un error, refresque la página")
    }}
      )
  })
  }else{
    this.openMensaje('Debes ingresar a tu cuenta AllTickets para realizar la compra, en caso de no tener, regístrate','openD');
  }
}

  openMensaje(mensajeT:string,openD?:string):void{
    let screenWidth = screen.width;
    let anchoDialog:string = '500px';
    let anchomax:string = '80vw';
    let altoDialog:string = '250';
    if(screenWidth<=600){
      anchoDialog = '100%';
      anchomax = '100%';
      altoDialog = 'auto';
    }
    const dialogRef = this.dialog.open(MensajeComponent, {
      width: anchoDialog,
      maxWidth: anchomax,
      height: altoDialog,
      data:{
        mensaje:mensajeT
      }
    });
    if(openD=='openD'){
      dialogRef.afterClosed().subscribe((result) => {
        this.openDialog2();
      });
    }
  }


  openDialog2(): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '600px',


    });

    dialogRef.afterClosed().subscribe(result => {

      this.ngOnInit()

    });

  }
    AbrirCarrito(): void {
        this.cargando=true
          this.idLocalidad = this.localidad.id
          let tipo: number = (this.localidad.tipo == 1 || this.localidad.tipo == 3) ? 4 : 1

            this.ordenService.crearOrdenClienteCompraPromotor(this.reserva.cantidad, this.idLocalidad, tipo, this.evento.id, this.usuario,this.promotor.correo).subscribe({
              next: response => {
                if (response.ordenId) {
                  this.router.navigate([`/eventos/carrito/${response.ordenId}`])
                }
                else if (response.mensaje) {
                  this.openMensaje(response.mensaje)
                }
  
  
              }, error: error => {
                this.openMensaje("Sucedio un error vuelva a intentar")
                this.cargando=false
              }
            })
  
    }



}