import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: false
})
export class AppComponent {
  visibleMenuResponsive: boolean = false;

  actualizarValor(evento: boolean) {
    this.visibleMenuResponsive = evento;
  }

  @HostListener('window:scroll', ['$event'])
  onScrollEvent(event: any) {
    if (this.visibleMenuResponsive && window.innerWidth < 920) {
      window.scrollTo(0, 0); // Deshabilitar scroll cuando el menú está activo
    }
  }
}


