
<section>
  <div class="img11">
  
    <div class="filtroNaranja1">
      <h1 class="centrado4 rounded nombre1  "><strong>Reserva para el evento {{evento?.nombre}}</strong></h1>

    </div>
  </div>
</section>




<div class="pagar cetrarTexto container border mt-5 p-3" *ngIf="!cargando">
    <p><strong>Nombre de la Reserva:</strong> {{usuario.nombre}}</p>
    <p><strong>Nombre del promotor:</strong> {{promotor.nombre}}</p>
    <p><strong>Localidad: </strong>{{localidad?.nombre}}</p>
    <p><strong>Numero Boletas: </strong>{{reserva.cantidad}}</p>
    <p><strong>Valor Total: </strong>{{valorTotal | currency:'COP'}}</p>



    <button  class="btn action_button btnAplicar2" (click)="AbrirCarrito()">IR A PAGAR</button>
     
</div>


<section *ngIf="cargando">
    <div class="container-fluid contendor-spinner-2" >
      <div class="spinner"></div>
    </div>
  </section>