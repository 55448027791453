export class Usuario {
   

    nombre: string;
    numeroDocumento: string;
    tipoDocumento: string
    usuario: string
    correo:string;
    contrasena:string
    tipo:string
    permisosDeEditor: boolean
    roles:string[]
    
}