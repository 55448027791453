<div class="spinner-overlay" *ngIf="cargando">
  <div class="spinner"></div>
</div>
<section class = "menuFront">
  <div class="barraNav 1" [ngClass]="{ 'menu-30': visibleMenuResponsive }">
    <div class="zona1 2">
      <a routerLink="/home" class="menu-r-p1">
        <img class="logoAT" src="../assets/images/img/logo2.webp" />
        <img
          class="logo-respon"
          src="../../assets/images/img/logo.png"
          alt=""
        />
      </a>
      <div class="menu-r-p2">
        <div class="menu-container" (click)="cambiarVisibilidadMenuResponsive()">
          <div class="menu-icon">
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
          </div>
        </div>

      </div>

    </div>
    <div
      class="menuNav 3"
      [ngClass]="{ 'visible': visibleMenuResponsive }"
      >
      <ul [ngClass]="{ 'flex-ul': visibleMenuResponsive, 'enlacesMenu': !visibleMenuResponsive }" (click)="cambiarVisibilidadMenuResponsive()">
          <li class="cajasMenu">
              <img
                class="img-pendientes"
                src="../../assets/images/Vectores/casa.png"
                alt=""png
              />
              <a  class="colorText"routerLink="home">Home</a>
          </li>
          <li *ngIf="!this.autenticacion.getUsuario();" class ="cajasMenu">
            <img
              class="img-pendientes"
              src="../../assets/images/Vectores/crear_1.png"
              alt=""
            />

            <a (click)="openDialog()" class="colorText">Regístro</a>
          </li>

          <li routerLink="active" *ngIf="this.autenticacion.getUsuario();"  routerLink="/eventos/carrito-de-compras" class ="cajasMenu">
            <img
              class="img-pendientes"
              src="../../assets/images/img/cart-shopping-solid.png"
              alt=""
            />
            <i class="fa-solid fa-cart-shopping" style="color: #f7dba7;"></i>
            <a class="colorText">Pendientes</a>
          </li>

          <li routerLinkActive="active"  *ngIf="!this.autenticacion.getUsuario();"        (click)="openDialog2()" class ="cajasMenu">
            <img
              class="img-pendientes"
              src="../../assets/images/Vectores/login_Mesa de trabajo 1 copia 36.png"
              alt=""
            />
            <a

            class="colorText"
              >Ingresar</a
            >
          </li>

          <li routerLinkActive="active"  *ngIf="this.autenticacion.getUsuario();" routerLink="/usuarios/usuario/{{usuario}}" class ="cajasMenu">
            <img
              class="img-pendientes"
              src="../../assets/images/Vectores/login_Mesa de trabajo 1 copia 36.png"
              alt=""
            />
            <a class="colorText">Mi Perfil</a>
          </li>
          <li routerLinkActive="inactive" *ngIf="this.autenticacion.getUsuario();" routerLink="/logout" class ="cajasMenu" >
            <img
            class="img-pendientes"
            src="../../assets/images/Vectores/cerrar-sesion.png"
            alt=""
            >
            <a class="colorText">Salir</a>
          </li>
        </ul>
    </div>
  </div>
</section>
