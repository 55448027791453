<div class="contenedor-general">
  <div class="negro-general" *ngIf="visibleMenuResponsive"></div>

  <div class="contenedor-menu" [ngClass]="{'esconder': visibleMenuResponsive}">
    <app-menu (valorCambiado)="actualizarValor($event)"></app-menu>
  </div>

  <div class="contenedor-info" [ngClass]="{'contenedor-info-largo': visibleMenuResponsive}">
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>

</div>


