
export class Codigo {
   
    id:number;
    numeroDocumento: string;
    nombre: string;
    correo:string;
    tipo_documento: string;
    contrasena:string;

    celular:string;
	
    activo: boolean;

    publicidad: boolean;
  
    tipo:string;
    


    
}