import { Component, OnInit } from '@angular/core';
import { CodigoValidacionDataService } from '../service/data/codigo-validacion-data.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-confirmar-registro',
  templateUrl: './confirmar-registro.component.html',
  styleUrls: ['./confirmar-registro.component.css'],
  standalone: false
})
export class ConfirmarRegistroComponent implements OnInit{


  idCodigo
  message:string
  cargando
  constructor(private service: CodigoValidacionDataService, private route: ActivatedRoute){

  }

  ngOnInit(): void {
    this.cargando=true
      this.route.paramMap.subscribe(params=>{
        this.idCodigo= params.get('id')
        this.service.crearUsuarioDesdeCodigo(this.idCodigo).subscribe({next:response=>{
          this.message= response.mensaje
          this.cargando=false
        },error:error=>{
          this.message="Sucedio un error por favor vuelva a empezar el proceso de registro"
          this.cargando=false
        }})
      })
  }
}
