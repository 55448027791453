
import { ResumirPipe } from './pipes/resumir.pipe';
import { EventosComponent } from './eventos/eventos.component';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

import { NosotrosComponent } from './nosotros/nosotros.component';

import { ContactanosComponent } from './contactanos/contactanos.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { ErrorComponent } from './error/error.component';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { LogoutComponent } from './logout/logout.component';
import { RegistrarseComponent } from './registrarse/registrarse.component';
import { ResumirPipe2 } from './pipes/resumir.pipe copy';
import { LOCALE_ID, NgModule } from '@angular/core';
import localeEs from '@angular/common/locales/es-CO';
import { registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatIconModule } from '@angular/material/icon';
import { MensajeComponent } from './mensaje/mensaje.component';
import { RedesSocialesComponent } from './redes-sociales/redes-sociales.component';

import { ComoComprarComponent } from './como-comprar/como-comprar.component';
import { OlvidoComponent } from './olvido/olvido.component';

import { ReservasPromotorComponent } from './reservas-promotor/reservas-promotor.component';
import { TokenInterceptor } from './service/interceptors/token.interceptor';
import { AuthInterceptor } from './service/interceptors/auth.interceptor';

import { ReservaBoletaComponent } from './reserva-boleta/reserva-boleta.component';
import { MensajeLinkComponent } from './mensaje-link/mensaje-link.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmarRegistroComponent } from './confirmar-registro/confirmar-registro.component';
import { RecuperarContrasenaComponent } from './recuperar-contrasena/recuperar-contrasena.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule  } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';

import { HoraPipe2 } from './pipes/horas2.pipe';


//SERVICIOS

//FIN SERVICIOS
registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    NosotrosComponent,
    ContactanosComponent,
    LoginComponent,
    ErrorComponent,
    MenuComponent,
    RedesSocialesComponent,
    FooterComponent,
    LogoutComponent,
    RegistrarseComponent,
    ResumirPipe,
    ResumirPipe2,
    MensajeComponent,
    ComoComprarComponent,
    OlvidoComponent,
    ReservasPromotorComponent,
    MensajeLinkComponent,
    ReservaBoletaComponent,
    EventosComponent,
    HomeComponent,
    ConfirmarRegistroComponent,
    RecuperarContrasenaComponent,
    HoraPipe2

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatIconModule,
    NgxGoogleAnalyticsModule.forRoot(environment.ga), // Configuración de Google Analytics
    NgxGoogleAnalyticsRouterModule,
  ],

  providers: [
    Meta,
    { provide: LOCALE_ID, useValue: 'es-CO' },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
