
<section class="contenedor-logout">

  <p class="gracias">¡GRACIAS POR CONFIAR EN ALLTICKETS!</p>

  <img class="lolo" src="../../assets/images/img/lolo-logout.webp" alt="">

  <p class="slogan">VIVELO AL MAXIMO!!!</p>

</section>
