import { UsuariosDataService } from './../service/data/usuarios-data.service';
import { Cliente } from './../usuario/cliente.model';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Md5 } from 'ts-md5';
import { MensajeComponent } from '../mensaje/mensaje.component';
import { MatDialog } from '@angular/material/dialog';
import { Codigo } from '../models/codigo.model';

@Component({
  selector: 'app-registrarse',
  templateUrl: './registrarse.component.html',
  styleUrls: ['./registrarse.component.css'],
  standalone: false
})
export class RegistrarseComponent implements OnInit {
  usuario: Codigo;
  confimarcionCorreo: String
  confimarcionDocumento: null
  aceptoTerminos: boolean
  aceptoTratamiento: boolean


  constructor(private service: UsuariosDataService, private router: Router, public dialog: MatDialog) { }


  ngOnInit(): void {
    this.confimarcionCorreo = ""
    this.confimarcionDocumento = null
    this.aceptoTerminos = false
    this.aceptoTratamiento = false
    this.usuario = new Codigo()
    this.function()
  }

  //validar el correo y si esta bien, se vuelve verde, si no, sigue rojo
validateEmail(){
  const emailField = document.getElementById('email') as HTMLInputElement;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailPattern.test(this.usuario.correo)) {
      emailField.style.borderLeft = '5px solid green';
    } else {
      emailField.style.borderLeft = '5px solid red';
    }
}

  saveUsuario() {


    if (!this.usuario.correo.includes(" ")) {


      if (this.aceptoTerminos) {
        if(this.aceptoTratamiento){
        if (this.confimarcionCorreo == this.usuario.correo) {

          if (this.confimarcionDocumento == this.usuario.numeroDocumento) {


            if (!this.usuario.numeroDocumento.includes(" ") && !this.usuario.numeroDocumento.includes(".")) {
              var md5 = new Md5()

              var contra = this.usuario.contrasena;
              this.usuario.contrasena = md5.appendStr(contra).end().toString();
              

              this.service.createCliente(this.usuario).subscribe(response => {

                response
                if(response.mensaje=="Los datos provisionados ya se encuentran registrados"){
                  this.openMensaje(response.mensaje)
                  this.usuario.contrasena = ""
                }
                else{
                  this.openMensaje(response.mensaje, 'closeAll');
                }

              },
                error => {
                  error
                  this.usuario.contrasena = ""
                }

              )


            }
            else {
              //alert("El número de documento no puede contener espacios ni puntos")
              this.openMensaje('El número de documento no puede contener espacios ni puntos');
            }


          }
          else {
            //alert("Verifica el numero de documento")
            this.openMensaje('Verifica el número de documento');
          }
        }
        else {
          //alert("Verifica el correo")
          this.openMensaje('Verifica el correo');
        }
      }
      else{
        this.openMensaje('Debes aceptar tratamiento de datos');
      }
      }
      else{
        this.openMensaje('Debes aceptar términos y condiciones');
      }


    }
    else {
      //alert("El correo no permite espacios en blanco")
      this.openMensaje('El correo no permite espacios en blanco');
    }





  }
  function() {
    var myInput = document.getElementById('bloquear');
    myInput.onpaste = function (e) {
      e.preventDefault();
      alert("esta acción está prohibida");
    }

    myInput.oncopy = function (e) {
      e.preventDefault();
      alert("esta acción está prohibida");
    }

    var myInput = document.getElementById('bloquear2');
    myInput.onpaste = function (e) {
      e.preventDefault();
      alert("esta acción está prohibida");
    }

    myInput.oncopy = function (e) {
      e.preventDefault();
      alert("esta acción está prohibida");
    }
  }

  publicidad() {
    if (this.usuario.publicidad == true) {
      this.usuario.publicidad = false
    }
    else {
      this.usuario.publicidad = true
    }
  }

  aceptarTerminos(){
    if (this.aceptoTerminos == true) {
      this.aceptoTerminos = false
    }
    else {
      this.aceptoTerminos = true
    }
  }

  aceptarTratamiento(){
    if (this.aceptoTratamiento == true) {
      this.aceptoTratamiento = false
    }
    else {
      this.aceptoTratamiento = true
    }
  }





  openMensaje(mensajeT: string, openD?: string): void {
    let screenWidth = screen.width;
    let anchoDialog: string = '500px';
    let anchomax: string = '80vw';
    let altoDialog: string = '250';
    if (screenWidth <= 600) {
      anchoDialog = '100%';
      anchomax = '100%';
      altoDialog = 'auto';
    }
    const dialogRef = this.dialog.open(MensajeComponent, {
      width: anchoDialog,
      maxWidth: anchomax,
      height: altoDialog,
      data: {
        mensaje: mensajeT
      }
    });
    if (openD == 'closeAll') {
      dialogRef.afterClosed().subscribe((result) => {
        this.dialog.closeAll();
      });
    }
  }


}
