import { Component, OnInit } from '@angular/core';
import { RecuperacionDataService } from '../service/data/recuperacion-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MensajeComponent } from '../mensaje/mensaje.component';
import { MatDialog } from '@angular/material/dialog';
import { Md5 } from 'ts-md5';

@Component({
  selector: 'app-recuperar-contrasena',
  templateUrl: './recuperar-contrasena.component.html',
  styleUrls: ['./recuperar-contrasena.component.css']
})
export class RecuperarContrasenaComponent implements OnInit{


  valido:boolean
  contrasena:string
  contrasenaConfirmar:string
  id

  constructor(private service:RecuperacionDataService, private route:ActivatedRoute, private dialog:MatDialog, private router:Router){

  }

  ngOnInit(): void {
      this.route.paramMap.subscribe(params=>{
        this.id=params.get('id')
        this.service.olvidoContrasenia(this.id).subscribe({next:response=>{
          this.valido= response.valido
        },error:error=>{

        }})
      })
      
  }
  saveContrasena(){
    if(this.contrasena==this.contrasenaConfirmar && this.contrasena){

       var md5 = new Md5();

      let contrasenaFinal = md5.appendStr(this.contrasena).end().toString();
      this.service.cambiarContrasena(this.id,contrasenaFinal).subscribe({next:response=>{
        if(response.valido){
          this.openMensaje("Contraseña exitosamente cambiada")
          
        }else{
          this.openMensaje("No se pudo realizar el proceso, por favor vuelva a solciitar la recuperacion de contraseña")
        }
        this.router.navigate(["/home"])
      },error:error=>{
        this.openMensaje("No se pudo realizar el proceso, por favor vuelva a solciitar la recuperacion de contraseña")
      }})
    }else{
      this.openMensaje("Las contraseñas deben coincidir y no pueden estar vacias")
    }
  }

  openMensaje(mensajeT: string): void {
    let screenWidth = screen.width;
    let anchoDialog: string = '500px';
    let anchomax: string = '80vw';
    let altoDialog: string = '250';
    if (screenWidth <= 600) {
      anchoDialog = '100%';
      anchomax = '100%';
      altoDialog = 'auto';
    }
    const dialogRef = this.dialog.open(MensajeComponent, {
      width: anchoDialog,
      maxWidth: anchomax,
      height: altoDialog,
      data: {
        mensaje: mensajeT
      }
    });
  }
}
