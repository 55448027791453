import { RouteGuardUsuarioService } from './service/route-guard-usuario.service';
import { RoutGuardPromotorService } from './service/rout-guard-promotor.service';
import { LogoutComponent } from './logout/logout.component';
import { ErrorComponent } from './error/error.component';
import { LoginComponent } from './login/login.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ComoComprarComponent } from './como-comprar/como-comprar.component';

import { ReservasPromotorComponent } from './reservas-promotor/reservas-promotor.component';

import { ReservaBoletaComponent } from './reserva-boleta/reserva-boleta.component';
import { ConfirmarRegistroComponent } from './confirmar-registro/confirmar-registro.component';
import { RecuperarContrasenaComponent } from './recuperar-contrasena/recuperar-contrasena.component';


const routes: Routes = [

  {
    path: 'reservas/:id',
    component: ReservasPromotorComponent,
  },
  {
    path: 'reserva-tickets/:id',
    component: ReservaBoletaComponent,
  },
  {
    path: 'usuarios',

    loadChildren: () =>
      import('./usuario/usuario.module').then((m) => m.UsuarioModule),
  },

  {
    path: 'home',
    component: HomeComponent,
  },

  {
    path: 'eventos',
    loadChildren: () =>
      import('./eventos/eventos.module').then((n) => n.EventosModule),
  },
  {
    path: 'confirmar-registro/:id',
    component: ConfirmarRegistroComponent,
  },
  {
    path: 'cambio-contrasena/:id',
    component: RecuperarContrasenaComponent,
  },
  ,
  {
    path: 'como-comprar',
    component: ComoComprarComponent,
  },
  {
    path: 'nosotros',
    component: NosotrosComponent,
  },

  {
    path: 'login',
    component: LoginComponent,
  },


  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [RouteGuardUsuarioService],
  },



  {
    path: '',
    component: HomeComponent,
  },

  {
    path: '**',
    component: ErrorComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
