import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from './../login/login.component';
import { RegistrarseComponent } from './../registrarse/registrarse.component';

import { HardcodedAutheticationService } from './../service/hardcoded-authetication.service';
import { Component, HostListener, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Cliente } from '../usuario/cliente.model';
import { ContactanosComponent } from '../contactanos/contactanos.component';



@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  cliente: Cliente;
  usuario:string;
  nombreBuscador:string
  cc:string
  nombre:string
  visibleMenuResponsive:boolean = false;
  cargando = false;
  isMobileView: boolean = window.innerWidth < 920;

  @Output() valorCambiado  = new EventEmitter<boolean>();

  constructor(private router: Router,public autenticacion: HardcodedAutheticationService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.visibleMenuResponsive=false

    this.cargarUsuario()
  }



  abrirCampoBuscador(){
 ;
    this.router.navigate(['/eventos/buscador/',this.nombreBuscador])

  }
  cargarUsuario(){
    this.usuario=this.autenticacion.getUsuario();
  }


  openDialog(): void {
    this.cargando = true;
    const dialogRef = this.dialog.open(RegistrarseComponent, {
      width: '600px',
      height:'90%'


    });
    dialogRef.afterOpened().subscribe(() => {
      this.cargando = false;
    });

    dialogRef.afterClosed().subscribe(() => {
      this.cargando = false;
    });
  }

  openDialog3(): void {
    this.cargando = true;
    const dialogRef = this.dialog.open(ContactanosComponent, {
      width: '600px',
      height:'90%'


    });
    dialogRef.afterOpened().subscribe(() => {
      this.cargando = false;
    });

    dialogRef.afterClosed().subscribe(() => {
      this.cargando = false;
    });
  }



  openDialog2(): void {
    this.cargando = true;
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '600px',


    });

    dialogRef.afterOpened().subscribe(() => {
      this.cargando = false;
    });

    dialogRef.afterClosed().subscribe(() => {
      this.cargando = false;
      this.cargarUsuario();
    });
  }

  cambiarVisibilidadMenuResponsive(){
    if (window.innerWidth <= 920) {
      this.visibleMenuResponsive = !this.visibleMenuResponsive;
      this.valorCambiado.emit(this.visibleMenuResponsive);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(window.innerWidth < 920){
      this.visibleMenuResponsive=false
      this.valorCambiado .emit(false);
    }
    else{
      this.visibleMenuResponsive=false
      this.valorCambiado .emit(false);
    }
  }
}
