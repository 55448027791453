<div class="spinner-overlay" *ngIf="cargando">
  <div class="spinner"></div>
</div>

<section class="Banner" content="width=device-width m-xs-5" *ngIf="imagenesTipo4?.length > 0">
  <div class="row">
    <div class="col-12 p-0">
      <section id="banner" class="carousel slide" data-ride="carousel" data-interval="3000">

        <!--Imagenes-->
        <div class="carousel-inner outerLink">

          <div class="carousel-item active h-25" *ngFor="let img of imagenesTipo4; let i = index" [class.active]="i === 0">
            <img src="{{img.url}}" class="imgCarrusel align-items-center " (click)="irAlEvento(img.eventoId)">

          </div>
        </div>

        <!--Controles-->

        <a *ngIf="imagenesTipo4[1]" href="#banner" class="carousel-control-prev" data-slide="prev"><span
            class="carousel-control-prev-icon"></span></a>
        <a *ngIf="imagenesTipo4[1]" href="#banner" class="carousel-control-next" data-slide="next"><span
            class="carousel-control-next-icon"></span></a>


      </section>
    </div>
  </div>
</section>

<div class=" centrarTxt pt-3">
  <h2 class="titulo-eventos">EVENTOS</h2>
  <hr class="linea-naranja2" />
</div>

<div class="contenedor-buscador">
  <div class="contenedor-flex2">
    <div class="inputs-contenedor2 margen-top">
      <input type="text" placeholder="Buscar por nombre/artista" [(ngModel)]="busqudaNombre" class="buscadores lupa buscadores-2 miniBusca ">
    </div>
    <div class="inputs-contenedor2 margen-top ">
      <select class="buscadores-2 triangulo" [(ngModel)]="idCiudad">
        <option value="-1" selected>Selecciona ciudad</option>
        <option value="{{ciudad.id}}" *ngFor="let ciudad of ciudades">{{ciudad.nombre}}</option>
      </select>
    </div>
    <div class="inputs-contenedor2 margen-top">
      <select class="buscadores-2 triangulo" [(ngModel)]="tipoEvento">
        <option value="-1" selected>Selecciona genero</option>
        <option value="Fiesta">Fiesta</option>
        <option value="Concierto Reggaeton">Concierto Reggaeton</option>
        <option value="Concierto Rap">Concierto Rap</option>
        <option value="Exposicion Arte">Exposición Arte</option>
        <option value="Concierto Electronica">Concierto Electrónica</option>
        <option value="Concierto Despecho">Concierto Despecho</option>
        <option value="Comedia">Comedia</option>
        <option value="Rock">Rock</option>
        <option value="Festival">Festival</option>
        <option value="Balada-Pop">Balada-Pop</option>
        <option value="Conferencia">Conferencia</option>
        <option value="Concierto musica tropical">Concierto musica tropical</option>
        <option value="Deporte">Deporte</option>
        <option value="Vallenato">Vallenato</option>
        <option value="Escolar">Escolar</option>
        <option value="Espectaculo">Espectaculo</option>
        <option value="Pop">Pop</option>
      </select>

    </div>
    <div class="button-contenedor  margen-top">
      <button class="action_button2" (click)="buscar()">Buscar</button>
    </div>
  </div>
</div>



<section class="container-fluid " style="margin-top: 0%; margin-bottom: 1%; ">


  <div class="row ">

    <div class=" col-6 col-sm-6  col-md-6  pl-sm-5 pr-sm-5 pl-md-2 pr-md-2 col-lg-4 col-xl-3 contenedor8"
      *ngFor="let evento of eventos">
      <div class="hover-overlay border rounded imgagenperfil" style="background-color: black; width: 100%;">

        <div class="contenedor2">
          <img *ngIf="evento.soldOut" class="sold_out" src="../../assets/images/img/SOLDOUT.png" alt="">
          <a routerLink="/eventos/evento/{{evento.id}}" class="outerLink">
            <div class="oscurecer">
              <img src="{{darURL(evento)}}" *ngIf="evento.imagenes.length>0" class=" rounded  darkableImage " />


            </div>
            <p class="centrado2"><strong> Compra ya tus Tickets para el evento {{evento.nombre}}</strong>

            </p>
          </a>
        </div>
      </div>

      <div class="border rounded container-fluid px-0 gris contenedorabajo">

        <div class=" contenedor borde">
          <div class="col-12 tituloEvento">
            <h2 class=" rounded titulos-1 "><strong> <a class="titulos"
                  routerLink="/eventos/evento/{{evento.id}}">{{evento?.nombre |resumir2}}</a></strong>
            </h2>
            <div class="lineaOrange"> </div>
          </div>
          <div class="espacioA">
            <div class="col-12  border-right fechaCiudad ">
              <img class="logoCal" src="../../assets/images/Vectores/Vectores_Mesa de trabajo 1 copia 39.png" alt="">
              <p class="fechas "><strong>{{darFecha(evento)}} </strong></p>
            </div>
            <div class="col-12  border-right fechaCiudad ">
              <img class="logoCal" src="../../assets/images/Vectores/Vectores_Mesa de trabajo 1 copia 37.png" alt="">
              <p class="fechas "><strong>{{ evento?.horaInicio | horas2}} </strong></p>
            </div>
            <div class="col-12  border-right fechaCiudad ">
              <img class="logoCal" src="../../assets/images/Vectores/Vectores_Mesa de trabajo 1 copia 38.png" alt="">
              <p class="fechas "><strong>{{evento?.ciudad?.nombre }} </strong></p>
            </div>

          </div>

          <div class="contenedorInfo">
            <div class=" botonVer">
              <button *ngIf="!evento.soldOut" class="action_button   inf bg-boton"
                routerLink="/eventos/evento/{{evento.id}}">Info.</button>
            </div>

          </div>

        </div>

      </div>
    </div>

    <div class="centrar-flex colorLetra" *ngIf="eventos.length==0 && cargando == false">
      <strong>Ningún Evento Encontrado</strong>
    </div>

  </div>
</section>
