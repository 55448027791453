import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css'],
  standalone: false
})
export class ErrorComponent implements OnInit {

  mensajeError ="Sucedió un error, por favor vuelve a la página principal";
  constructor() { }

  ngOnInit(): void {
  }

}
