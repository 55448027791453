
import { Component, OnInit } from '@angular/core';


import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-contactanos',
  templateUrl: './contactanos.component.html',
  styleUrls: ['./contactanos.component.css'],
  standalone: false
})
export class ContactanosComponent implements OnInit {

  nombre='';
  correo='';
  mensaje='';

  constructor( private dialog: MatDialog) { }

  ngOnInit(): void {
  }


  onSubmit() {
    alert("Gracias por contactarnos")
    this.dialog.closeAll()
  
    
  }


}
