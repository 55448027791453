<footer id="footer" class="mt-5">

  <div class="colFooter">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-3 txtFooter ">
        <div class="bold">ALL TICKETS S.A.S.</div>
        <div class="lato light">NIT. 901.265.612-3</div>

        <br>
        <div class="bold">Numero de Contacto</div>
        <div class="lato light">+57 305 371 0154</div>
          <p >All Rights Reserved &copy; 2024</p>
          <a class="terminos" href="https://codigos.allticketscol.com/TerminosYCondiciones.html"  target="_blank">Términos y Condiciones</a>
          <br>
          <a class="terminos" href="https://codigos.allticketscol.com/TratamientoDeDatos.html"  target="_blank">Tratamiento de Datos</a>
      </div>

      <div class="col-12 col-sm-6 col-md-3  txtFooter">
        <div class="bold">Ayuda:</div>
        <div class="lato light">Contáctanos - PQRS</div>
        <div class="lato light">Preguntas Frecuentes</div>
        <div><a class="linkFoot lato light" href="https://www.allticketscol.com/nosotros">Conócenos </a></div>
      </div>

      <div class="col-12 col-sm-6 col-md-3 txtFooter ftDere">
        <div class="bold">Legal:</div>
        <div> <a class="linkFoot lato light" href="https://www.allticketscol.com/terminosYcondiciones">Términos y
            Condiciones </a>
        </div>
        <div> <a class="linkFoot lato light" href="https://www.allticketscol.com/tratamientoDatos">Tratamiento de datos
          </a></div>
        <div> <a class="linkFoot lato light" href="https://www.sic.gov.co/">SIC </a></div>
      </div>

      <div class="col-12 col-sm-6 col-md-3 txtFooter ftDere">
        <div class="bold">Redes sociales:</div>
        <div class=" cajaFoot">
          <a href="https://api.whatsapp.com/send?phone=573209644716&text=Hola%20All%20Tickets,%20me%20comunico%20con%20ustedes%C2%A0porque:"><img class="imRedes" src="./../../assets/images/img/logow.png" alt=""></a>
          <a href="https://www.instagram.com/all_tickets/"> <img class="imRedes"
              src="./../../assets/images/img/logoig.png" alt=""></a>
          <a href="https://www.facebook.com/allticketscol"> <img class="imRedes"
              src="./../../assets/images/img/logofb.png" alt=""></a>

        </div>
      </div>
    </div>

    <div class="divFin">
      <div class="lineaFooter"></div>
      <div class="finFooter bold montserrat">All Rights Reserved &copy; 2024</div>
    </div>



  </div>
</footer>