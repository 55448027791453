

export const API_URL ="https://back.allticketscol.com"
export const API_URL2 ="https://back2.allticketscol.com"
export const API_URL3 ="http://localhost:5000"


 const endpoint="https://back.allticketscol.com"
//const endpoint="http://localhost:8090"


export const API_URL_EVENTOS=endpoint+"/api/eventos"
export const API_URL_USUARIOS=endpoint+"/api/usuarios"
export const API_URL_PAGOS=endpoint+"/api/pagos"
export const API_URL_MAPAS=endpoint+"/api/mapas"
export const API_URL_PARTICULARIDADES=endpoint+"/api/particularidades"
export const API_URL_PROMOTORES=endpoint+"/api/promotores"
export const API_URL_AUTH=endpoint+"/api/security"

export const respuesta ="https://allticketscol.com"

export const IVA =0.19

//Endpoints Locales para pruebas
// export const API_URL_EVENTOS="http://localhost:8090/api/eventos"
// export const API_URL_PAGOS="http://localhost:8090/api/pagos"
// export const API_URL_PARTICULARIDADES="http://localhost:8090/api/particularidades";
// export const API_URL_MAPAS="http://localhost:8090/api/mapas"
// export const API_URL_AUTH="http://localhost:8090/api/security"
// export const API_URL_USUARIOS="http://localhost:8090/api/usuarios"
// export const API_URL_PROMOTORES="http://localhost:8090/api/promotores"



