
<div class="flex-centrar">
<div class="reset-password-container">
    <h2 class="azul">Restablecer Contraseña</h2>
    <hr class="linea-naranja">
    <form (ngSubmit)="!todoForm.invalid && saveContrasena()" class="mt-4" #todoForm="ngForm">
     
        <div class="form-group">
            <label for="new-password">Nueva Contraseña</label>
            <input type="password" id="new-password" name="new-password" required required [(ngModel)]="contrasena">
        </div>
        <div class="form-group">
            <label for="confirm-password">Confirmar Contraseña</label>
            <input type="password" id="confirm-password" name="confirm-password" required required [(ngModel)]="contrasenaConfirmar">
        </div>
        <button class="btn botonLogin" type="submit">Cambiar contraseña</button>
    </form>
</div>

</div>