import { RegistrarseComponent } from './../registrarse/registrarse.component';


import { Component, OnInit } from '@angular/core';


import { OlvidoComponent } from '../olvido/olvido.component';
import { AuthService } from '../service/seguridad/auth.service';
import { Usuario } from '../service/usuario.model';
import { MensajeComponent } from '../mensaje/mensaje.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


 
  usuario:Usuario
  errorMessage="Invalid credentials";
  invalidLogin =false;
  constructor( 
    //public autenticacion: HardcodedAutheticationService,
    public dialog: MatDialog,
    private auth:AuthService) { 

  }

  ngOnInit(): void {
   this.usuario = new Usuario();
  }


  
  handleLogin(){
  
    
    if(this.usuario.correo==null || this.usuario.contrasena==null){
      //alert('Username o Password vacios');
      this.openMensaje('Username o Password vacios');
      return;
    }

    this.auth.logout();

    this.usuario.correo=this.usuario.correo.trim();
    
    this.auth.login(this.usuario).subscribe({next:response => {

       this.auth.guardarUsuario(response.access_token);
       this.auth.guardarToken(response.access_token);
      
       let usuario = this.auth.usuario;

      
      
  },
  error:error => {
    if(error.status == 400){
      //alert('Usuario o clave incorrectos');
      this.openMensaje('Usuario o clave incorrectos');
    }
    this.usuario = new Usuario();
    this.invalidLogin =true;
  }}
  );


  }
  olvidoContrasenia(){
    const dialogRef = this.dialog.open(OlvidoComponent, {
      width: '600px'      
    });

    dialogRef.afterClosed().subscribe(result => {
      
      
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(RegistrarseComponent, {
      width: '600px',
      height:'750px'
      
    });

    dialogRef.afterClosed().subscribe(result => {
      
      
    });
  }

  openMensaje(mensajeT:string,openD?:string):void{
    let screenWidth = screen.width;
    let anchoDialog:string = '500px';
    let anchomax:string = '80vw';
    let altoDialog:string = '250';
    if(screenWidth<=600){
      anchoDialog = '100%';
      anchomax = '100%';
      altoDialog = 'auto';
    }
    const dialogRef = this.dialog.open(MensajeComponent, {
      width: anchoDialog,
      maxWidth: anchomax,
      height: altoDialog,
      data:{
        mensaje:mensajeT
      }
    });
  }
}
