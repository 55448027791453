<section class="container mt-3 mb-3 ">
    <div class="row">
        
    <div class ="col-12 login  contenederoLogin">
        <mat-icon class="float-right rounded pt-2 pb-2 pl-3 pr-3 botonesCerrar" mat-button mat-dialog-close> cancel_presentation</mat-icon>
        <div class="fondoBlanco rounded shadow-lg">
            <div class=" pt-4 pb-4 padding">
                <h2 class="colorLetras tamanoTitulo"><strong>Iniciar Sesión</strong></h2>
                <h5 class="azul"><strong>Tu Perfil All Tickets - All Products</strong></h5>
            </div>
     
            <form (ngSubmit)="handleLogin()" class="pl-4 pr-4 margenResponsive">
                <div class="mt-3 form-group margenResponsive">
                    
                    <input type="text" name= "username" class="rounded form-control formCorreo"  placeholder="Correo" [(ngModel)]="usuario.correo" >
                    <small class="colorLetras">Ingresa con tu correo</small>
                </div>

                

                <div class="mt-3 form-group margenResponsive">
                    
                    <input type="password" name="password" class="rounded form-control formCorreo" placeholder="Contraseña" [(ngModel)]="usuario.contrasena">
                </div>
                
                
            
                <div class="mt-3 mb-3 margenResponsive">
                    <small *ngIf ='invalidLogin' class="text-danger" >{{errorMessage}}</small>
                    <br *ngIf ='invalidLogin'>
                    <button class="btn botonLogin mt-3 iniciar centrar-flex"  type="submit">Iniciar Sesión</button>
                </div>
            </form>
            <div class="centrarTexto">
                <a class="colorLetras" (click)="olvidoContrasenia()">¿Olvidaste tu contraseña?</a>
            </div>
            <hr class="L75">
        
            <button class="btn botonCrearCuenta mb-5" (click)="openDialog()" type="submit">Crear cuenta nueva</button>
        </div>
    </div>
    
</div>
</section>