


<div class="flex-centrar">
<div class="container">
    <div class="spinner-overlay" *ngIf="cargando">
        <div class="spinner"></div>
      </div>
    <div class="thank-you-message">
        <h1>{{message}}</h1>
        
        <a routerLink="/home" class="action_button">Ver eventos</a>
    </div>

    <div class="social-media">
        <h2>Síguenos también en nuestras redes sociales</h2>
        <div class="icons">
            <a href="#"><img src="../../assets/images/img/logofb.png" alt="Facebook"></a>
            <a href="#"><img src="../../assets/images/img/logoig.png" alt="Instagram"></a>
            <a href="#"><img src="../../assets/images/img/logow.png" alt="WhatsApp"></a>
        </div>
    </div>
</div>
</div>