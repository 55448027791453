import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { LoginComponent } from '../login/login.component';
import { Reserva } from '../models/reserva.model';
import { Promotor } from 'src/app/models/promotor.model';
import { PromotorDataService } from '../service/data/promotor-data.service';
import { ReservasDataService } from '../service/data/reservas-data.service';
import { UsuariosDataService } from '../service/data/usuarios-data.service';
import { HardcodedAutheticationService } from '../service/hardcoded-authetication.service';

import { Evento } from '../eventos/evento.model';
import { OrdenDataService } from '../service/data/orden-data.service';
import { MensajeComponent } from '../mensaje/mensaje.component';

import { MatDialog } from '@angular/material/dialog';
import { ClientePagos } from '../models/cliente-pagos.model';
import { Localidad } from '../models/localidad.model';

@Component({
  selector: 'app-reservas-promotor',
  templateUrl: './reservas-promotor.component.html',
  styleUrls: ['./reservas-promotor.component.css'],
  standalone: false
})
export class ReservasPromotorComponent implements OnInit {
  
  reserva: Reserva
  usuario: ClientePagos
  user;
  miId
  promotor: Promotor
  evento: Evento;
  localidad: Localidad
  idLocalidad:number

  cargando:boolean

  constructor(
    private autenticador: HardcodedAutheticationService
    , private route: ActivatedRoute,
    private clienteService: UsuariosDataService,
    private reservasService: ReservasDataService,
    private promotorService: PromotorDataService,
    private ordenService: OrdenDataService,
    private router: Router,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {

    this.cargando =true;
    
    this.evento = new Evento();

    this.usuario = new ClientePagos()

    this.reserva = new Reserva()
   
    this.route.paramMap.subscribe(params => {
      this.miId = params.get("id")
      this.reservasService.getPorId(this.miId).subscribe((response) => {
        this.reserva = response.reserva
        this.evento = response.evento
        this.localidad = response.localidad

        if (this.reserva == null) {
          //alert("Esta reserva no existe")
          this.openMensaje('Esta reserva no existe');
        }

        if(this.reserva.activa){

          this.user = this.autenticador.getUsuario();

          if (this.user) {
            this.clienteService.getCliente(this.user).subscribe((response) => {
              
              this.usuario = response.usuario
              if (this.reserva.documentoReserva == this.usuario.numeroDocumento) {
  
                this.promotorService.getPorId(this.reserva.numeroDocumento).subscribe(
                  (response) => {
                    this.promotor = response.promotor;
                  },
                  (error) => {
                    this.openMensaje('Error al obtener el promotor');
                  }
                );
                this.cargando=false

              }
              else {
                //alert("Esta reserva no esta a tu nombre")
                this.openMensaje('Esta reserva no esta a tu nombre');
              }


  
            })

          }
  
          else {
            this.openMensaje('Debes ingresar a tu cuenta AllTickets para realizar la compra, en caso de no tener, regístrate', 'openD');
          }

        }

       
      }
      )
    })


  }

  openDialog2(): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '600px',


    });

    dialogRef.afterClosed().subscribe(result => {

      this.ngOnInit()

    });
  }

  openMensaje(mensajeT: string, openD?: string): void {
    let screenWidth = screen.width;
    let anchoDialog: string = '500px';
    let anchomax: string = '80vw';
    let altoDialog: string = '250';
    if (screenWidth <= 600) {
      anchoDialog = '100%';
      anchomax = '100%';
      altoDialog = 'auto';
    }
    const dialogRef = this.dialog.open(MensajeComponent, {
      width: anchoDialog,
      maxWidth: anchomax,
      height: altoDialog,
      data: {
        mensaje: mensajeT
      }
    });
    if (openD == 'openD') {
      dialogRef.afterClosed().subscribe((result) => {
        this.openDialog2();
      });
    } else if (openD == 'onInit') {
      this.ngOnInit();
    }
  }

  

  abrirCarrito() {
    this.cargando=true
    this.idLocalidad = this.localidad.id
    let tipo: number = (this.localidad.tipo == 1 || this.localidad.tipo == 3) ? 4 : 1

      this.ordenService.crearOrdenClienteCompraNumeradosPromotor( tipo, this.evento.id, this.usuario.numeroDocumento, this.reserva.tickets,this.promotor.correo).subscribe({
        next: response => {
          if (response.ordenId) {
            this.router.navigate([`/eventos/carrito/${response.ordenId}`])
          }
          else if (response.mensaje) {
            this.openMensaje(response.mensaje)
          }


        }, error: error => {
          this.openMensaje("Sucedio un error vuelva a intentar")
          this.cargando=false
        }
      })
  }


  calcularTotal(): number {
    let valorTotal = 0;
    this.reserva?.tickets.forEach(ticket => {

      let totalTicket = ticket.precio + ticket.servicio + ticket.servicio_iva;
      if (ticket.cantidadAsientos > 1) {
        totalTicket *= ticket.cantidadAsientos;
      }
      valorTotal += totalTicket;
    });

    return valorTotal
  }

  calcularTotalAsientos(): number {
    let cantidad = 0;
    this.reserva?.tickets.forEach(ticket => {
      cantidad += ticket.cantidadAsientos;
    });
    return cantidad
  }

  getNumerosTicekts(): string {
    let numeros="";

    let tickets = this.reserva?.tickets

    if(tickets[0].cantidadAsientos>1 && tickets[0].tipo==0){
      numeros = "Palco completo"
    }
    else if(tickets[0].numero_dentro_de_evento!=null){
      tickets.forEach(t=>{
        numeros += t.numero_dentro_de_evento+", "
      })
      // Remover la última coma y espacio
      numeros = numeros.slice(0, -2);
    }
    else if(tickets[0].numero_dentro_de_evento==null){
      numeros = "No numerados"

    }
    
    return numeros
  }


  




  
}